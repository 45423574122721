<template>
  <div class="sm:flex sm:items-center">
    <div class="sm:flex-auto">
      <h1 class="text-3xl pb-3 pl-2 font-extrabold tracking-tight text-gray-900">
        {{ $t("Stores") }}
      </h1>
    </div>
  </div>

  <div>
    <StoreList />
  </div>
</template>

<script>
import StoreList from "@/views/stores/StoreList.vue";

export default {
  components: {
    StoreList,
  },

  data() {
    return {};
  },
};
</script>
