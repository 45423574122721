export const isNetworkError = (e) => e.message === "Network Error";

export const isTimeoutError = (e) =>
  e.response?.status === 500 &&
  e.response?.data?.errors &&
  e.response.data.errors[0]?.title === "timeout";

export const isNotFoundError = (e) =>
  e.response ? e.response.status === 404 : false;

export const isUnprocessableEntityError = (e) =>
  e.response ? e.response.status === 422 : false;

export const isForbiddenError = (e) =>
  e.response ? e.response.status === 403 : false;

export const isUnauthorized = (e) =>
  e.response ? e.response.status === 401 : false;

export const isNotAcceptableError = (e) =>
  e.response ? e.response.status === 406 : false;

export const isConflictError = (e) =>
  e.response ? e.response.status === 409 : false;

export const isMaintenanceError = (e) =>
  !!e.response && e.response.status === 503;

export const isServerError = (e) => !!e.response && e.response.status >= 500;

export const isUnexpectedError = (e) =>
  e.response
    ? e.response.status >= 400 &&
      !isNotFoundError(e) &&
      !isUnprocessableEntityError(e) &&
      !isForbiddenError(e) &&
      !isUnauthorized(e) &&
      !isNotAcceptableError(e) &&
      !isConflictError(e) &&
      !isMaintenanceError(e)
    : false;
