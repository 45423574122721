<template>
  <TabsNavigation :tabs="tabs" />
</template>

<script>
import { TabsNavigation } from "@estaly/ui";

export default {
  components: { TabsNavigation },

  data: () => ({
    tabs: [
      { name: "analytics_business", path: "/analytics/business", displayName: "Suivi des rémunérations" },
      { name: "analytics_retails", path: "/analytics/retail", displayName: "Suivi des ventes" },
    ],
  }),
};
</script>
