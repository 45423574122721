import Rollbar from "rollbar";

export const rollbar = new Rollbar({
  accessToken: process.env.VUE_APP_ROLLBAR_POST_CLIENT_ITEM_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.VUE_APP_ESTALY_ENV,
    client: {
      javascript: {
        code_version: "1.0.0",
      },
    },
  },
});

export const RollbarPlugin = {
  install(app) {
    app.config.errorHandler = (error, vm, info) => {
      console.error(error);
      rollbar.error(error, { vueComponent: vm, info });
      if (app.config.devtools) {
        console.error(error);
      }
    };
    app.provide("rollbar", rollbar);
  },
};
