<template>
  <CollapsableContent v-if="matchedContracts" title="Offres proposées" :open="false">
    <div class="overflow-x-auto">
      <div class="inline-block min-w-full align-middle">
        <div class="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <table class="min-w-full divide-y divide-gray-300">
            <thead class="bg-gray-50">
              <tr class="text-xs">
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  {{ $t("Id") }}
                </th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  {{ $t("Title") }}
                </th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  {{ $t("Price") }}
                </th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  {{ $t("Price band") }}
                </th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  {{ $t("Term length") }} ({{ $t("Months") }})
                </th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  {{ $t("Reference Id") }}
                </th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  {{ $t("Sku") }}
                </th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  {{ $t("Multi product offer") }}
                </th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  {{ $t("Store theorical commission amount") }}
                </th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  {{ $t("Insurer") }}
                </th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  {{ $t("Insurer premium") }}
                </th>
                <th>Statut</th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr
                v-for="(matchedContract, index) in matchedContracts"
                :key="index"
                :class="['text-xs hover:bg-gray-100', matchedContract.toBePublished ? 'bg-red-200' : '']"
              >
                <td class="px-3 py-3.5 font-bold">
                  {{ matchedContract.id }}
                </td>
                <td class="px-3 py-3.5">
                  {{ matchedContract.title }}
                </td>
                <td class="px-3 py-3.5 font-bold">
                  <FieldSpan :style-to-add="'bg-yellow-200'" :value="matchedContract.price" />
                </td>
                <td class="px-3 py-3.5">
                  {{ matchedContract.priceBandMin }} -
                  {{ matchedContract.priceBandMax }}
                </td>
                <td class="px-3 py-3.5">
                  <FieldSpan :style-to-add="'bg-gray-200'" :value="matchedContract.termLength" />
                </td>
                <td class="px-3 py-3.5">
                  <p v-if="matchedContract.referenceId">
                    {{ matchedContract.referenceId }}
                  </p>
                  <p class="font-bold" v-else>—</p>
                </td>
                <td class="px-3 py-3.5">
                  {{ matchedContract.sku }}
                </td>
                <td class="px-3 py-3.5">
                  <FieldSpan
                    :style-to-add="matchedContract.multiProduct ? 'bg-green-200' : 'bg-red-200'"
                    :value="matchedContract.multiProduct"
                  />
                </td>
                <td class="px-3 py-3.5">
                  {{ matchedContract.theoricalStoreCommissionAmount }}
                </td>
                <td class="px-3 py-3.5">
                  {{ matchedContract.insurer }}
                </td>
                <td class="px-3 py-3.5">
                  {{ matchedContract.insurancePremium }}
                </td>
                <td class="px-3 py-3.5">
                  <Switch
                    v-model="matchedContract.enabled"
                    @update:modelValue="toggleEnable(matchedContract)"
                    :label="matchedContract.enabled ? 'Activé' : 'Désactivé'"
                  ></Switch>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </CollapsableContent>
</template>

<script>
import BackofficeService from "@/api/services/backoffice.js";
import CollapsableContent from "@/components/utils/CollapsableContent.vue";
import Switch from "@/components/utils/Switch.vue";
import FieldSpan from "@/components/utils/FieldSpan.vue";
import { mapActions } from "vuex";

export default {
  components: { CollapsableContent, Switch, FieldSpan },
  props: ["matchedContracts"],

  methods: {
    ...mapActions("notifications", ["notify"]),

    async toggleEnable(matchedContract) {
      if (!confirm(`Changer la disponibilité de l'offre ${matchedContract.title} ${matchedContract.price} ?`)) return;

      const { success } = await BackofficeService.updateMatchedContract(matchedContract.id, {
        matched_contract: { enabled: matchedContract.enabled },
      });
      if (!success) return;

      this.$emit("matched-contract-update");
      await this.notify({
        category: "simple",
        type: "success",
        title: "Mise à jour effectuée",
      });
    },
  },
};
</script>
