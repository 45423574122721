<template>
  <div>
    <h1 class="text-center font-extrabold text-xl mb-5">Ajouter une verticale</h1>
    <div class="grid grid-cols-2 gap-4 my-3">
      <Selector
        id="category"
        label="Verticale"
        name="category"
        required
        v-model="formData.category"
        :options="categoriesSorted"
      />
      <FormField
        id="store_fee"
        label="Taux de commission marchand"
        name="store_fee"
        trailing-add-ons="%"
        required
        v-model="formData.storeFee"
      />
    </div>
    <div class="flex justify-end">
      <GenericButton @click="submit">Ajouter</GenericButton>
    </div>
  </div>
</template>

<script>
import GenericButton from "@/components/utils/GenericButton.vue";
import Selector from "@/components/utils/Selector.vue";
import FormField from "@/components/utils/FormField.vue";
import BackofficeService from "@/api/services/backoffice.js";
import { mapActions } from "vuex";

export default {
  components: {
    GenericButton,
    Selector,
    FormField,
  },

  props: ["store"],

  data() {
    return {
      formData: {
        category: null,
        storeFee: 30,
      },
      categories: [],
      productCategoryNames: [],
      productCategoryMarketingPages: [],
    };
  },

  computed: {
    categoriesSorted() {
      const options = this.categories.map((category) => ({
        ...category,
        label:
          this.$t(category.label) === category.label
            ? category.label
            : `${category.label} (${this.$t(category.label)})`,
      }));
      // Sort & set "other_or_multiple" as last
      return options.sort((a, b) =>
        a.value === "other_or_multiple" ? 1 : b.value === "other_or_multiple" ? -1 : a.label.localeCompare(b.label),
      );
    },
    payload() {
      return {
        ...this.formData,
        storeFee: parseFloat(this.formData.storeFee) / 100,
      };
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    async submit() {
      const { success } = await BackofficeService.createBusinessVertical(this.store.id, this.payload);
      if (!success) return;

      await this.notify({
        category: "simple",
        type: "success",
        title: "Mise à jour effectuée",
      });
      this.$emit("created");
    },

    async loadFormOptions() {
      const response = await BackofficeService.newBusinessVertical(this.store.id);
      if (!response?.success) return;

      this.categories = response.data.categories;
      this.productCategoryNames = response.data.product_category_names;
      this.productCategoryMarketingPages = response.data.product_category_marketing_pages;
    },
  },

  async mounted() {
    await this.loadFormOptions();
  },
};
</script>
