<template>
  <div>
    <div class="flex justify-between">
      <PageHeader title="Challenges" />
      <div>
        <CreateButton @click="openModal = true">Créer un challenge</CreateButton>
      </div>
    </div>

    <CreateChallengeModal :open-modal="openModal" @close="openModal = false" @challengeCreated="retrieveChallenges" />

    <Tabs :tabs="tabs">
      <template #liste_des_challenges>
        <ChallengeList :challenges="challenges" @refreshChallenges="retrieveChallenges" />
      </template>
      <template #suivi_des_challenges>
        <ChallengeDetails :challenges="challenges" />
      </template>
    </Tabs>
  </div>
</template>

<script>
import { Tabs } from "@estaly/ui";
import PageHeader from "@/components/utils/PageHeader.vue";
import ChallengeList from "@/views/challenges/ChallengeList.vue";
import ChallengeDetails from "@/views/challenges/ChallengeDetails.vue";
import CreateChallengeModal from "@/views/challenges/create/CreateChallengeModal.vue";
import CreateButton from "@/components/utils/CreateButton.vue";
import BackofficeService from "@/api/services/backoffice";

export default {
  components: {
    PageHeader,
    Tabs,
    ChallengeList,
    ChallengeDetails,
    CreateChallengeModal,
    CreateButton,
  },
  data() {
    return {
      tabs: [
        { name: "liste_des_challenges", displayName: "Liste des challenges", current: true },
        { name: "suivi_des_challenges", displayName: "Suivi des challenges", current: false },
      ],
      challenges: [],
      openModal: false,
    };
  },
  async mounted() {
    await this.retrieveChallenges();
  },
  methods: {
    async retrieveChallenges() {
      const response = await BackofficeService.getChallenges();
      this.challenges = response.data.challenges;
    },
  },
};
</script>
