<template>
  <div>
    <Selector
      id="challenge-selector"
      name="challenge"
      label="Sélectionnez un challenge"
      v-model="selectedChallenge"
      :options="challengeOptions"
      required
    />

    <Selector
      v-if="stores.length"
      id="store-selector"
      name="store"
      label="Sélectionnez un magasin"
      v-model="selectedStore"
      :options="storeOptions"
      required
    />

    <div class="mt-4 space-y-4" v-if="selectedStore">
      <Table :columns="storeTableColumns" :data="storeTableData" />
      <Table :columns="sellerTableColumns" :data="sellerTableData" />
    </div>
  </div>
</template>

<script>
import Selector from "@/components/utils/Selector.vue";
import { Table } from "@estaly/ui";
import BackofficeService from "@/api/services/backoffice";

export default {
  components: {
    Selector,
    Table,
  },

  props: {
    challenges: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data() {
    return {
      selectedChallenge: "",
      stores: [],
      store: null,
      selectedStore: "",
      sellers: [],
    };
  },

  computed: {
    challengeOptions() {
      return this.challenges.map((challenge) => ({
        label: `${challenge.id} ${challenge.title || ""}`,
        value: challenge.id,
      }));
    },
    storeOptions() {
      return this.stores.map((store) => ({
        label: store.salesChannel,
        value: store.id,
      }));
    },

    storeTableColumns() {
      return [
        { field: "salesChannel", label: "Magasin", bold: true },
        { field: "email", label: "Email" },
        { field: "planSalesCount", label: "Nombre de contrats", numeric: true },
        { field: "planSalesActiveCount", label: "Nombre de contrats actifs", numeric: true },
        { field: "rewardAmount", label: "Gain brut", numeric: true, price: true },
        { field: "netRewardAmount", label: "Gain net", numeric: true, price: true },
      ];
    },

    storeTableData() {
      return this.store
        ? [
            {
              salesChannel: this.store.salesChannel,
              email: this.store.email,
              planSalesCount: this.store.planSalesCount,
              planSalesActiveCount: this.store.planSalesActiveCount,
              rewardAmount: this.store.rewardAmount,
              netRewardAmount: this.store.netRewardAmount,
            },
          ]
        : [];
    },

    sellerTableColumns() {
      return [
        { field: "name", label: "Vendeur", bold: true },
        { field: "email", label: "Email" },
        { field: "planSalesCount", label: "Nombre de contrats", numeric: true },
        { field: "planSalesActiveCount", label: "Nombre de contrats actifs", numeric: true },
        { field: "rewardAmount", label: "Gain brut", numeric: true, price: true },
        { field: "netRewardAmount", label: "Gain net", numeric: true, price: true },
      ];
    },

    sellerTableData() {
      return this.sellers.map((seller) => ({
        name: seller.name,
        email: seller.email,
        planSalesCount: seller.planSalesCount,
        planSalesActiveCount: seller.planSalesActiveCount,
        rewardAmount: seller.rewardAmount,
        netRewardAmount: seller.netRewardAmount,
      }));
    },
  },

  watch: {
    async selectedChallenge(newChallengeId) {
      if (newChallengeId) {
        await this.retrieveStores();
      } else {
        this.stores = [];
      }
    },

    async selectedStore(newStoreId) {
      if (newStoreId) {
        await this.retrieveChallengePerformances();
      }
    },
  },

  methods: {
    async retrieveStores() {
      const response = await BackofficeService.getStores({ challenge_id: this.selectedChallenge });
      if (response?.success) this.stores = response.data.stores;
    },
    async retrieveChallengePerformances() {
      const response = await BackofficeService.getChallengePerformances(this.selectedChallenge, this.selectedStore);
      if (response?.success) {
        this.store = response.data.store;
        this.sellers = response.data.sellers;
      }
    },
  },
};
</script>
