<template>
  <div v-if="isThereAnyContract">
    <div class="mt-3 text-center sm:mt-5">
      <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
        {{ $t("Available Contracts") }}
      </DialogTitle>

      <div class="px-4 sm:px-6 lg:px-8">
        <div class="mt-8 flex flex-col">
          <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table class="min-w-full divide-y divide-gray-300">
                  <thead class="bg-gray-50">
                    <tr>
                      <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        {{ $t("Title") }}
                      </th>
                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        {{ $t("Store Fee") }}
                      </th>
                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        {{ $t("Pricing") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-200 bg-white">
                    <tr v-for="contract in variant.matchedContracts" :key="contract.id">
                      <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
                        {{ contract.title }}
                      </td>
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {{ formatPrice(contract.storeCommission, contract.price.currencyCode) }}
                      </td>
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {{ formatPrice(contract.price.amount, contract.price.currencyCode) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center mt-5" v-if="!isThereAnyContract">
    <p>{{ $t("No contract available for this variant") }}</p>
  </div>
</template>
<script>
import { formatPrice } from "@estaly/ui/src/utils/price_formatter";

export default {
  props: ["variant"],

  computed: {
    isThereAnyContract: function () {
      return this.variant.matchedContracts.length !== 0;
    },
  },

  methods: {
    formatPrice,
  },
};
</script>
