<template>
  <div class="min-h-screen min-w-screen flex sm:items-center justify-center py-0 md:py-12 px-0 md:px-4 sm:px-6 lg:px-8">
    <div
      class="bg-white w-full md:w-1/2 lg:w-1/3 lex items-center justify-center py-20 px-4 md:px-12 rounded-md shadow-2xl"
    >
      <div class="w-full space-y-8">
        <div>
          <img class="mx-auto h-14 w-auto" src="@/assets/large_logo_estaly_alternative.png" alt="Estaly" />
          <h2 class="mt-6 text-center text-3xl font-light text-gray-900">Réinitialiser votre mot de passe</h2>
        </div>
        <VeeForm v-slot="{ handleSubmit }" :validation-schema="schema" as="div">
          <form class="mt-8 space-y-6" @submit="handleSubmit($event, handleResetPassword)">
            <Field type="hidden" name="remember" value="true" />
            <div class="rounded-md shadow-sm -space-y-px">
              <div>
                <label for="email-address" class="block text-sm font-medium leading-6 text-gray-900">
                  {{ $t("Email address") }}
                </label>
                <div class="my-2">
                  <Field
                    id="email-address"
                    name="email"
                    type="email"
                    autocomplete="email"
                    required=""
                    class="block w-full rounded-md border-0 px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                    placeholder="Saisir une adresse email"
                    v-model="email"
                  />

                  <ErrorMessage name="email" class="mt-2 text-sm text-red-600" />
                </div>
              </div>
            </div>
            <div>
              <button
                type="submit"
                class="group relative w-full flex justify-center inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow disabled:cursor-wait disabled:opacity-75 bg-primary-light text-primary-dark"
              >
                <span class="absolute left-0 inset-y-0 flex items-center pl-3"></span>
                <p class="font-body uppercase text-lg">Envoyer</p>
              </button>
            </div>
          </form>
        </VeeForm>
      </div>
    </div>
    <div class="absolute bottom-4 right-4 md:right-8">
      <span class="text-sm font-semibold cursor-pointer text-primary-dark">
        <a href="mailto:contact@estaly.co">Nous contacter</a>
      </span>
    </div>
  </div>
</template>

<script>
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { mapActions, mapState } from "vuex";
import UserService from "@/api/services/user";

export default {
  components: {
    Field,
    VeeForm,
    ErrorMessage,
  },

  data() {
    const schema = yup.object({
      email: yup.string().required(this.$t("Email is required")).email(this.$t("Email is not valid")),
    });

    return {
      email: "",
      schema,
    };
  },

  computed: {
    ...mapState("auth", ["signedIn"]),
  },

  created() {
    if (this.signedIn) this.$router.push("/");
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    async handleResetPassword() {
      const { success } = await UserService.forgotPassword(this.email);
      if (!success) return;

      this.notify({
        type: "success",
        category: "simple",
        title: "Réinitialisation de mot de passe",
        text: "Vous avez reçu un mail pour réinitialiser votre mot de passe",
      });
    },
  },
};
</script>
