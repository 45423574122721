function isNull(input) {
  return input == null;
}

function isEmpty(input) {
  return (
    (!input && input !== 0) ||
    input.length === 0 ||
    (Object.getPrototypeOf(input) === Object.prototype &&
      Object.getOwnPropertyNames(input).length === 0)
  );
}

function isBlank(input) {
  return isNull(input) || isEmpty(input);
}

/*
isPresent(undefined) === false &&
isPresent(null) === false &&
isPresent("") === false &&
isPresent([]) === false &&
isPresent({}) === false &&
isPresent(0) === true &&
isPresent(1) === true &&
isPresent("Hello") === true &&
isPresent([1, 2, 3]) === true &&
isPresent({ name: "John" }) === true
*/
export function isPresent(input) {
  return !isBlank(input);
}

// Deprecated: use isPresent instead
export function isStringValid(input) {
  return input !== undefined && input !== null && input !== "";
}

// Deprecated: use isPresent instead
export function isObjectValid(input) {
  return input !== undefined && input !== null;
}
