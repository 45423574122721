<template>
  <Modal :open="openModal" @close="closeModal">
    <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-primary-light">
      <DocumentReportIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
    </div>

    <VeeForm v-slot="{ handleSubmit }" :validation-schema="schema" as="div" ref="discountCreationForm">
      <form @submit="handleSubmit($event, createChallenge)">
        <div class="space-y-12 sm:space-y-16">
          <div>
            <h2 class="text-base font-semibold leading-7 text-gray-900">Créer un Challenge</h2>
            <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
              Entrez les informations concernant le nouveau challenge
            </p>

            <hr />

            <FormField
              id="title"
              label="Titre"
              name="title"
              required
              placeholder="Titre du challenge"
              v-model="challenge.title"
            />
            <ErrorMessage name="title" class="mt-2 text-sm text-red-600" />

            <FormField
              id="startDate"
              label="Date de début"
              name="startDate"
              type="date"
              required
              v-model="challenge.startDate"
            />
            <ErrorMessage name="startDate" class="mt-2 text-sm text-red-600" />

            <FormField
              id="endDate"
              label="Date de fin"
              name="endDate"
              type="date"
              required
              v-model="challenge.endDate"
            />
            <ErrorMessage name="endDate" class="mt-2 text-sm text-red-600" />
          </div>
        </div>

        <CreateSalesTarget @update:salesTargets="addSalesTarget" @remove:salesTargets="removeSalesTarget" />

        <div class="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="submit"
            class="inline-flex justify-center text-button-font-primary bg-primary uppercase rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
          >
            Créer
          </button>
        </div>
      </form>
    </VeeForm>
  </Modal>
</template>
<script>
import CreateSalesTarget from "@/views/challenges/create/CreateSalesTarget.vue";
import Modal from "@/components/modal/Modal.vue";
import { DocumentReportIcon } from "@heroicons/vue/outline";
import FormField from "@/components/utils/FormField.vue";
import { ErrorMessage, Form as VeeForm } from "vee-validate";
import * as yup from "yup";
import BackofficeService from "@/api/services/backoffice";
import { mapActions } from "vuex";

const schema = yup.object({
  title: yup.string().required("Renseignez le titre du challenge"),
  startDate: yup.date().required("Ajoutez la date de début"), // TODO : Upgrade yup to fix null date issue
  endDate: yup.date().required("Ajoutez la date de fin"),
});

export default {
  components: {
    Modal,
    DocumentReportIcon,
    VeeForm,
    ErrorMessage,
    FormField,
    CreateSalesTarget,
  },

  data() {
    return {
      schema,
      challenge: this.newChallenge(),
    };
  },

  computed: {
    isDiscountTypeOffered() {
      return this.selectedDiscountType === "offered";
    },
  },

  props: ["openModal"],

  methods: {
    ...mapActions("notifications", ["notify"]),

    closeModal() {
      this.$emit("close");
    },

    newChallenge() {
      return {
        title: "",
        startDate: null,
        endDate: null,
        salesTargets: [],
      };
    },

    async createChallenge() {
      const { success } = await BackofficeService.createChallenge(this.challenge);
      if (!success) return;

      await this.notify({
        category: "simple",
        type: "success",
        title: "Challenge créé",
        text: "Vous pouvez maintenant ajouter des stores au challenge.",
      });
      this.$emit("close");
      this.$emit("challengeCreated");
      this.challenge = this.newChallenge();
    },

    addSalesTarget(newValue) {
      this.challenge.salesTargets.push(newValue);
    },

    removeSalesTarget(newValue) {
      let salesTarget = this.challenge.salesTargets.find(
        (group) => group.target === newValue.target && group.reward === newValue.reward,
      );

      if (salesTarget) {
        let index = this.challenge.salesTargets.indexOf(salesTarget);

        if (index !== -1) {
          this.challenge.salesTargets.splice(index, 1);
        }
      }
    },
  },
};
</script>
