import { nextTick } from "vue";
import { createI18n } from "vue-i18n";

const numberFormats = {
  fr: {
    currency: {
      style: "currency",
      currency: "EUR",
      useGrouping: true,
      currencyDisplay: "symbol",
      trailingZeroDisplay: "stripIfInteger",
    },
    decimal: {
      style: "decimal",
      minimumSignificantDigits: 3,
      maximumSignificantDigits: 5,
    },
    percent: {
      style: "percent",
      useGrouping: false,
    },
  },
  "en-US": {
    currency: {
      style: "currency",
      currency: "USD",
      notation: "standard",
    },
    decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: "percent",
      useGrouping: false,
    },
  },
};

export let i18n;

export const SUPPORT_LOCALES = ["en", "fr"];

export function getI18n(options = { locale: "fr" }) {
  i18n ||= createI18n({
    ...options,
    missingWarn: false,
    legacy: false,
    globalInjection: true,
    numberFormats,
  });
  return i18n;
}

export function setupI18n(options = { locale: "fr" }) {
  i18n = getI18n(options);
  setI18nLanguage(i18n, options.locale, options.importMessages);
  return i18n;
}

export function setI18nLanguage(i18n, locale, importMessages) {
  if (i18n.mode === "legacy") {
    i18n.global.locale = locale;
  } else {
    i18n.global.locale.value = locale;
  }
  /**
   * NOTE:
   * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
   * The following is an example for axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */
  document.querySelector("html").setAttribute("lang", locale);
  loadLocaleMessages(i18n, locale, importMessages);
}

export async function loadLocaleMessages(i18n, locale, importMessages) {
  // load locale messages with dynamic import
  const messages = await importMessages(locale);

  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages.default);

  return nextTick();
}
