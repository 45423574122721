import { getI18n } from "./i18n";
import { isPresent } from "./validation";

/*
With default local "fr":

formatPrice(0): 0 €
formatPrice(1): 1 €
formatPrice(10.99): 10,99 €
formatPrice("10.99"): 10,99 €
formatPrice(undefined):
formatPrice(null):
formatPrice("10.99", "USD"): 10,99 $US
*/
export function formatPrice(price, currencyCode) {
  const floatPrice = parseFloat(price);
  if (isNaN(floatPrice)) return null;

  let options = {};
  if (isPresent(currencyCode)) options = { currency: currencyCode };

  const i18n = getI18n();
  return i18n.global.n(floatPrice, "currency", options);
}
