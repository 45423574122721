<template>
  <div v-if="currentClaim !== undefined && currentClaim !== null" class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="text-center mb-10">
      <GenericButton @onClick="redirectToClaimListPage">
        {{ $t("Back") }}
      </GenericButton>
    </div>
    <div v-if="doesClaimExists">
      <div class="flex align-center flex-col">
        <h1 class="text-3xl mb-2 pl-2 font-extrabold tracking-tight text-gray-900 text-center">
          {{ currentClaim.friendlyId }}
        </h1>
        <div class="flex justify-center">
          <span
            :class="[
              getClaimStatusBannerStyle(currentClaim.status.color),
              'inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium',
            ]"
          >
            {{ currentClaim.status.text }}
          </span>
        </div>
      </div>

      <div class="mt-4">
        <GenericButton
          v-if="currentClaim.status.value !== 'resolved' && currentClaim.requiredAction === 'upload_invoice'"
          @onClick="openClaimInvoiceUploadModal = true"
        >
          Ajouter la facture de réparation
        </GenericButton>
      </div>
      <div class="mt-4">
        <GenericButton v-if="currentClaim.status.value !== 'resolved'" @onClick="openUpdateStatusModal = true">
          Mettre à jour le statut
        </GenericButton>
      </div>
      <div class="mt-4">
        <GenericButton
          v-if="currentClaim.status.value !== 'resolved' && currentClaim.requiredAction === 'upload_quotation'"
          @onClick="openClaimQuotationUploadModal = true"
        >
          Ajouter le devis de réparation
        </GenericButton>
      </div>
      <div class="mt-4">
        <GenericButton
          v-if="currentClaim.claimDeductibleWaitingForPayment"
          @onClick="requestAndCopyClaimDeductiblePaymentUrl"
        >
          Copier le lien de paiement de la franchise
        </GenericButton>
      </div>
      <RequiredAction class="mt-10" v-if="doesClaimExists && currentClaim.requiredAction" :claim="currentClaim" />

      <ClaimHistoricalChanges v-if="doesClaimExists" :claim="currentClaim" class="border border-gray-300 mt-10" />
      <InvoiceInformation class="mt-10" :claim="currentClaim" />
      <QuotationInformation class="mt-10" :claim="currentClaim" />
      <VoucherInformation class="mt-10" :voucher="currentClaim.voucher" />
      <ClaimsClientIndex class="mt-10" :claim="currentClaim" />
      <ClaimCoverageInformation v-if="doesClaimExists && currentClaim.coverages" class="mt-10" :claim="currentClaim" />
      <ClaimInformation v-if="doesClaimExists" class="mt-10" :claim="currentClaim" />
      <PlanSaleInformation v-if="doesClaimExists" class="mt-10" :claim="currentClaim" />
      <ClaimProductInformation v-if="doesClaimExists" class="mt-10" :product="currentClaim.claimProduct" />
      <ClaimFormResponses v-if="doesClaimExists" class="mt-10" :claim="currentClaim" />

      <UpdateClaimStatusModal
        :open="openUpdateStatusModal"
        :claim="currentClaim"
        @close="openUpdateStatusModal = false"
      />
      <ClaimInvoiceUploadModal
        :open="openClaimInvoiceUploadModal"
        :claim="currentClaim"
        @close="openClaimInvoiceUploadModal = false"
      />
      <ClaimQuotationUploadModal
        :open="openClaimQuotationUploadModal"
        :claim="currentClaim"
        @close="openClaimQuotationUploadModal = false"
      />
    </div>
    <ClaimNotFound v-else />
  </div>
</template>

<script>
import GenericButton from "@/components/utils/GenericButton.vue";
import ClaimInformation from "@/components/claim/details/information/ClaimInformation.vue";
import PlanSaleInformation from "@/components/claim/details/information/PlanSaleInformation.vue";
import ClaimNotFound from "@/components/claim/list/utils/ClaimNotFound.vue";
import ClaimFormResponses from "@/components/claim/details/information/ClaimFormResponses.vue";
import QuotationInformation from "@/components/claim/details/quotation/QuotationInformation.vue";
import ClaimHistoricalChanges from "@/components/claim/details/information/ClaimHistoricalChanges.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import UpdateClaimStatusModal from "@/components/claim/details/information/action/UpdateClaimStatusModal.vue";
import RequiredAction from "@/components/claim/details/RequiredAction.vue";
import InvoiceInformation from "@/components/claim/details/invoice/InvoiceInformation.vue";
import ClaimInvoiceUploadModal from "@/components/claim/details/information/action/invoices/InvoiceUploadModal.vue";
import ClaimQuotationUploadModal from "@/components/claim/details/information/action/quotations/QuotationUploadModal.vue";
import VoucherInformation from "@/components/claim/details/information/VoucherInformation.vue";
import ClaimsClientIndex from "@/components/claim/details/information/ClaimsClientIndex.vue";
import ClaimCoverageInformation from "@/components/claim/details/information/ClaimCoverageInformation.vue";
import ClaimProductInformation from "@/components/claim/details/information/ClaimProductInformation.vue";
import BackofficeService from "@/api/services/backoffice";

export default {
  components: {
    ClaimsClientIndex,
    ClaimCoverageInformation,
    VoucherInformation,
    ClaimQuotationUploadModal,
    ClaimInvoiceUploadModal,
    InvoiceInformation,
    RequiredAction,
    UpdateClaimStatusModal,
    ClaimHistoricalChanges,
    QuotationInformation,
    GenericButton,
    ClaimInformation,
    PlanSaleInformation,
    ClaimNotFound,
    ClaimFormResponses,
    ClaimProductInformation,
  },

  computed: {
    ...mapGetters("claims", ["doesClaimExists"]),
    ...mapState("claims", ["currentClaim"]),
  },

  data: () => {
    return {
      openUpdateStatusModal: false,
      openClaimInvoiceUploadModal: false,
      openClaimQuotationUploadModal: false,
    };
  },

  beforeMount() {
    this.getClaim(this.$route.params.id);
  },

  methods: {
    ...mapActions("claims", ["getClaim", "reset"]),
    ...mapActions("notifications", ["notify"]),

    getClaimStatusBannerStyle: function (color) {
      return `bg-${color}-100 text-${color}-800`;
    },

    redirectToClaimListPage: function () {
      this.$router.back();
    },

    requestAndCopyClaimDeductiblePaymentUrl: async function () {
      const response = await BackofficeService.getClaimDeductiblePaymentUrl(this.$route.params.id);
      if (response?.success) this.copyToClipboard(response.data.url);
    },

    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.notify({
          category: "simple",
          type: "success",
          title: "Link copied to clipboard!",
        });
      });
    },
  },

  watch: {
    $route(to, from) {
      // not the same page
      if (to.matched[0] !== from.matched[0]) return;

      // same page, id has changed
      if (to.params.id !== from.params.id) this.getClaim(to.params.id);
    },
  },
};
</script>
