<template>
  <div class="pt-6 md:pt-0 relative overflow-x-auto">
    <Table :columns="tableColumns" :data="formattedData" :is-deletable="true" @delete="deleteChallenge" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Table } from "@estaly/ui";
import { formatPrice } from "@estaly/ui/src/utils/price_formatter";
import BackofficeService from "@/api/services/backoffice";

export default {
  components: {
    Table,
  },
  props: {
    challenges: {
      type: Array,
      required: true,
    },
  },

  computed: {
    tableColumns() {
      return [
        { field: "id", label: "ID", numeric: true, bold: true },
        { field: "title", label: "Titre" },
        { field: "startDate", label: "Début" },
        { field: "endDate", label: "Fin" },
        { field: "salesTargets", label: "Objectifs de vente", price: true },
        { field: "participants", label: "Participants" },
      ];
    },
    formattedData() {
      return this.challenges.map((challenge) => ({
        id: challenge.id,
        title: challenge.title,
        startDate: challenge.startDate,
        endDate: challenge.endDate,
        salesTargets: this.formatSalesTargets(challenge.salesTargets),
        participants: this.getFormattedOrganizations(challenge),
      }));
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    formatPrice,

    formatStartEndDates(challenge) {
      return `${challenge.startDate}${window.innerWidth < 768 ? " au " + challenge.endDate : ""}`;
    },

    formatSalesTargets(salesTargets) {
      return salesTargets
        .map((item) => `• Target: ${item.target} | Reward: ${this.formatPrice(item.reward)}`)
        .join("<br/>");
    },

    getFormattedOrganizations(challenge) {
      return challenge.organizations.map((org) => org.name).join(" - ");
    },

    async deleteChallenge(challenge) {
      if (!confirm(`Supprimer le challenge ${challenge.title} (${challenge.id}) ?`)) return;

      const { success } = await BackofficeService.deleteChallenge(challenge.id);
      if (!success) return;

      await this.notify({
        category: "simple",
        type: "success",
        title: "Challenge supprimé",
      });
      this.$emit("refreshChallenges");
    },
  },
};
</script>
