<template>
  <div>
    <PageHeader title="Leads" />
    <div class="mb-6 flex flex-col sm:w-1/4 sm:flex-row w-full">
      <DateRangePicker placeholder="Sélectionner une période" v-model="dateRange" use-query-params />
    </div>
    <SearchBar
      class="mb-6"
      :placeholder="$t('Rechercher un lead par nom, prénom, email ou numéro de téléphone')"
      :current-page="currentPage"
      :available-filters="availableFiltersWithOptions"
      :selected-filters="currentFilteringData"
      :initial-keywords="currentFilteringData.keywords"
      @updateFilterSearch="updateCurrentFilteringData"
      @updateKeywordSearch="updateKeywordSearch"
    />
    <Table :columns="columns" :data="leads" :is-updatable="true" @update="openLeadModal" />
    <Pagination
      :currentPage="currentPage"
      :pages="pages"
      :startIdx="startIdx"
      :endIdx="endIdx"
      :total="this.totalNumberOfLeads"
      @changePage="handleChangePage"
      v-if="pages.length > 1"
    />
  </div>
  <LeadModal
    :open="openModal"
    :lead="currentLead"
    :discounts="discounts"
    @close="openModal = false"
    @getLeads="getLeads"
  />
</template>

<script>
import PageHeader from "@/components/utils/PageHeader.vue";
import { DateRangePicker } from "@estaly/ui";
import SearchBar from "@/components/menu/SearchBar.vue";
import { Table } from "@estaly/ui";
import Pagination from "@/components/utils/Pagination.vue";
import LeadModal from "@/views/leads/LeadModal.vue";

import BackofficeService from "@/api/services/backoffice";
import { mapState } from "vuex";

const PAGE_SIZE = 25;

export default {
  components: { PageHeader, Table, DateRangePicker, SearchBar, Pagination, LeadModal },

  data() {
    return {
      leads: [],
      currentPage: 1,
      totalNumberOfLeads: 1,
      discounts: [],
      filters: {
        callback_date_status: [],
        status: [],
        type: [],
      },
      currentFilteringData: {
        callbackDateStatus: [],
        keywords: null,
        startDate: null,
        endDate: null,
        status: [],
        type: [],
      },
      columns: [
        { label: "Date de création", field: "creationDate" },
        { label: "Magasin", field: "source" },
        { label: "Email", field: "email" },
        { label: "Type", field: "type" },
        {
          label: "Statut",
          field: "status",
          class: {
            live: "p-1 rounded-lg bg-yellow-50 text-yellow-600",
            expired: "p-1 rounded-lg bg-gray-50 text-gray-600",
            converted: "p-1 rounded-lg bg-green-50 text-green-600",
            waiting_for_confirmation: "p-1 rounded-lg bg-blue-50 text-blue-600",
          },
        },
      ],
      openModal: false,
      currentLead: null,
    };
  },

  computed: {
    ...mapState("backoffice", ["isLoading"]),
    startIdx: function () {
      return (this.currentPage - 1) * PAGE_SIZE;
    },
    endIdx: function () {
      return Math.min(this.startIdx + PAGE_SIZE, this.totalNumberOfLeads);
    },
    pages: function () {
      if (this.isLoading || this.totalNumberOfLeads < PAGE_SIZE) {
        return [];
      }
      return [...Array(Math.ceil(this.totalNumberOfLeads / PAGE_SIZE)).keys()].map((e) => e + 1);
    },
    dateRange: {
      get() {
        return [this.currentFilteringData.startDate, this.currentFilteringData.endDate];
      },
      set(value) {
        if (value && value.length === 2) {
          this.updateDateFilters(value[0], value[1]);
        } else {
          this.updateDateFilters(null, null);
        }
      },
    },
    availableFiltersWithOptions() {
      return Object.fromEntries(
        Object.entries(this.filters).filter(([filterKey, filterOptions]) => filterKey && filterOptions?.length > 1),
      );
    },
  },

  created() {
    this.getLeads();
  },

  methods: {
    openLeadModal(lead) {
      this.currentLead = lead;
      this.openModal = true;
    },
    updateKeywordSearch(keywords) {
      this.currentFilteringData["keywords"] = keywords;
      this.currentPage = 1;
      this.getLeads();
    },
    updateDateFilters(startDate, endDate) {
      this.currentFilteringData["startDate"] = startDate;
      this.currentFilteringData["endDate"] = endDate;
      console.log(this.currentFilteringData);
      this.handleChangePage(1);
    },
    updateCurrentFilteringData(filterName, filterOptions) {
      this.currentFilteringData[filterName] = filterOptions;
      this.handleChangePage(1);
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getLeads();
    },
    async getLeads() {
      const response = await BackofficeService.getLeads(this.currentPage, this.currentFilteringData);
      this.filters = response.data.filters;
      this.leads = response.data.leads;
      this.totalNumberOfLeads = response.data.totalNumberOfLeads;
      this.discounts = [{ Id: null, title: "Aucune" }].concat(response.data.discounts);
    },
  },
};
</script>
