<template>
  <div>
    <PageHeader title="Declared Claims" />
    <SearchBar
      v-bind:title="$t('Claim filters')"
      v-bind:placeholder="$t('SearchClaims')"
      v-bind:current-page="this.currentPage"
      v-bind:available-filters="this.availableFilters"
      v-bind:selected-filters="this.selectedFilters"
      v-bind:initial-keywords="this.keywords"
      @updateFilterSearch="this.updateFilterSearch"
      @updateKeywordSearch="this.updateKeywordSearch"
    />
    <PaginatedClaimList
      class="mt-10"
      v-bind:total-number-of-claims="this.totalNumberOfClaims"
      v-bind:current-page="this.currentPage"
      v-bind:claims="this.claims"
      @changePage="this.handleChangePage"
    />
  </div>
</template>

<script>
import PageHeader from "@/components/utils/PageHeader.vue";
import PaginatedClaimList from "@/components/claim/list/PaginatedClaimList.vue";
import { mapActions, mapState } from "vuex";
import SearchBar from "@/components/menu/SearchBar.vue";

export default {
  components: {
    PageHeader,
    SearchBar,
    PaginatedClaimList,
  },

  computed: {
    ...mapState("claim_list", ["claims", "totalNumberOfClaims", "availableFilters", "selectedFilters", "keywords"]),
  },

  data() {
    return {
      currentPage: 1,
    };
  },

  methods: {
    ...mapActions("claim_list", ["getClaims", "updateFilter", "updateSearch"]),
    ...mapActions("notifications", ["notify"]),

    handleChangePage: function (page) {
      this.updatePage(page);
    },

    retrieveClaims: async function () {
      await this.getClaims({ page: this.currentPage });
    },

    updateFilterSearch: function (filterName, filterOptions) {
      this.updatePage(1, false);
      this.updateFilter({
        filterName: filterName,
        filterOptions: filterOptions,
        page: this.currentPage,
      });
    },

    updateKeywordSearch: function (keyword) {
      this.updatePage(1, false);
      this.updateSearch({
        searchString: keyword,
        page: this.currentPage,
      });
    },

    updatePage: function (newPage, refreshClaims = true) {
      this.currentPage = newPage;
      history.pushState({}, "", `?page=${newPage}`);
      if (refreshClaims) {
        this.retrieveClaims();
      }
    },
  },

  created() {
    this.currentPage = parseInt(this.$route.query.page) || 1;
    this.retrieveClaims();
  },
};
</script>
