import BackofficeService from "../../api/services/backoffice";
import { isObjectValid, isStringValid } from "@/utils/validation";

const initialState = {
  isLoading: false,
  allProducts: [],
  totalNumberOfProducts: null, // Total number of products that matches the filter criterias
  totalNumberOfProductsApproved: null, // Total number of parent products that are approved
  approvedProductsIds: [], // ids of the approved products that matches the filter criterias
  availableFilters: {
    productTypes: [],
    vendors: [],
    tags: [],
    offerStatuses: [],
    approvalStatuses: [],
    categories: [],
  },
  selectedFilters: {
    productTypes: [],
    vendors: [],
    tags: [],
    offerStatuses: [],
    approvalStatuses: [],
    categories: [],
  },
  search: "",
  organizations: [],
  stores: [],
  selectedOrganizationId: null,
  selectedStoreId: null,
};

export default {
  namespaced: true,

  state: initialState,

  getters: {
    isSearchInputNotEmpty: (state) => {
      return isStringValid(state.search);
    },
    getSelectedStore: (state) => {
      return state.organizations
        .filter((org) => org.id === state.selectedOrganizationId)
        .flatMap((org) => org.stores)
        .find((store) => store.id === state.selectedStoreId);
    },
    getSelectedOrganization: (state) => {
      return isObjectValid(state.selectedOrganizationId)
        ? state.organizations.find((org) => org.id === state.selectedOrganizationId)
        : null;
    },
  },

  mutations: {
    setProducts(state, products) {
      state.allProducts = products;
    },

    setAvailableFilters(state, { productTypes, vendors, tags, offerStatuses, approvalStatuses, categories }) {
      state.availableFilters.productTypes = productTypes;
      state.availableFilters.vendors = vendors;
      state.availableFilters.tags = tags;
      state.availableFilters.offerStatuses = offerStatuses;
      state.availableFilters.approvalStatuses = approvalStatuses;
      state.availableFilters.categories = categories;
    },

    setTotalNumberOfProducts(state, totalNumberOfProducts) {
      state.totalNumberOfProducts = totalNumberOfProducts;
    },

    setTotalNumberOfProductsApproved(state, totalNumberOfProductsApproved) {
      state.totalNumberOfProductsApproved = totalNumberOfProductsApproved;
    },

    setApprovedProductsIds(state, approvedProductsIds) {
      state.approvedProductsIds = approvedProductsIds;
    },

    setOrganizations(state, organizations) {
      state.organizations = organizations;
    },

    setStores(state, stores) {
      state.stores = stores;
    },

    setSelectedStoreId(state, selectedStoreId) {
      state.selectedStoreId = selectedStoreId;
    },

    setSelectedOrganizationId(state, selectedOrganizationId) {
      state.selectedOrganizationId = selectedOrganizationId;
    },

    setIsLoading(state, val) {
      state.isLoading = val;
    },

    setFilter(state, { filterName, filterOptions }) {
      state.selectedFilters[filterName] = filterOptions;
    },

    setSearch(state, searchString) {
      state.search = searchString;
    },
  },

  actions: {
    async getProducts({ state, commit }, { online, offline, title, source, parents, variants, page }) {
      const response = await BackofficeService.getProducts(
        state.selectedStoreId,
        state.selectedOrganizationId,
        state.selectedFilters.productTypes,
        state.selectedFilters.vendors,
        state.selectedFilters.offerStatuses,
        state.selectedFilters.approvalStatuses,
        state.selectedFilters.tags,
        state.selectedFilters.categories,
        state.search,
        title,
        source,
        parents,
        variants,
        online,
        offline,
        page,
      );
      if (!response?.success) return response;

      const {
        products,
        productTypes,
        vendors,
        tags,
        offerStatuses,
        approvalStatuses,
        totalNumberOfProducts,
        totalNumberOfProductsApproved,
        approvedProductsIds,
        categories,
      } = {
        ...response.data,
      };

      commit("setProducts", products);
      commit("setTotalNumberOfProducts", totalNumberOfProducts);
      commit("setTotalNumberOfProductsApproved", totalNumberOfProductsApproved);
      commit("setApprovedProductsIds", approvedProductsIds);
      commit("setAvailableFilters", {
        productTypes,
        vendors,
        tags,
        offerStatuses,
        approvalStatuses,
        categories,
      });

      return response;
    },

    async getOrganizations({ commit, state }) {
      const response = await BackofficeService.getOrganizations();
      if (!response?.success) return response;

      const organizations = response.data.organizations;
      commit("setOrganizations", organizations);
      const stores = organizations.flatMap((org) => org.stores);
      if (!isObjectValid(state.selectedStoreId)) {
        commit("setStores", stores);
      }
      return response;
    },

    async executeAction({ dispatch }, { product, action_type }) {
      const response = await BackofficeService.executeActionOnProducts([product.id], action_type);
      if (!response?.success) return response;

      return dispatch("getProducts", {
        page: 1,
        parents: true,
      });
    },

    updateSelectedOrganizationId({ commit, state }, organizationId) {
      commit("setSelectedOrganizationId", organizationId);
      const newStores = state.organizations.find((org) => org.id === organizationId).stores;
      commit("setStores", newStores);
      commit("setSelectedStoreId", null);
    },

    updateSelectedStoreId({ commit, dispatch }, { storeId }) {
      commit("setSelectedStoreId", storeId);
      return dispatch("getProducts", {
        page: 1,
        parents: true,
      });
    },

    updateFilter({ commit, dispatch }, { filterName, filterOptions, page, parents }) {
      commit("setFilter", { filterName, filterOptions });
      return dispatch("getProducts", {
        page: page,
        parents: parents,
      });
    },

    updateSearch({ commit, dispatch }, { searchString, page, parents }) {
      commit("setSearch", searchString);
      return dispatch("getProducts", {
        page: page,
        parents: parents,
      });
    },

    async updateProductCategory({ dispatch }, { product, newCategory }) {
      const response = await BackofficeService.updateProductCategory(product, newCategory);
      if (!response?.success) return response;

      return dispatch("getProducts", {
        page: 1,
        parents: true,
      });
    },

    withLoader({ commit }, action) {
      commit("setIsLoading", true);
      return action().finally(() => {
        commit("setIsLoading", false);
      });
    },
  },
};
