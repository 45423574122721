<template>
  <div>
    <div class="mt-5 flex flex-col">
      <div class="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div class="relative shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table class="min-w-full table-fixed divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="relative w-12 px-6 sm:w-16 sm:px-8">
                    <input
                      type="checkbox"
                      class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary sm:left-6"
                      :value="this.areAllProductsSelected"
                      :checked="this.areAllProductsSelected"
                      @change="this.handleProductsSelection"
                      :disabled="this.allProductsSelected"
                    />
                  </th>
                  <th scope="col" class="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                    {{ $t("Name") }}
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    {{ $t("Category") }}
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr
                  v-for="product in getOrderedProducts"
                  :key="product.id"
                  :class="[
                    'hover:bg-gray-200',
                    (isProductSelected(product) && 'bg-primary-light') ||
                      (!isProductSelected(product) && !product.variantsStatusValid && 'bg-red-200'),
                  ]"
                >
                  <td class="relative w-12 px-6 sm:w-16 sm:px-8">
                    <div
                      v-if="this.isProductSelected(product)"
                      class="absolute inset-y-0 left-0 w-0.5 bg-primary"
                    ></div>
                    <input
                      type="checkbox"
                      class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary sm:left-6"
                      :value="product.id"
                      :disabled="this.allProductsSelected"
                      @change="this.handleProductSelection($event, product)"
                      :checked="this.isProductSelected(product)"
                    />
                  </td>
                  <td
                    :class="[
                      'whitespace-nowrap py-4 pr-3 text-sm font-medium',
                      isProductSelected(product) ? 'text-primary' : 'text-gray-900',
                    ]"
                    @click="this.redirectToProductPage(product)"
                  >
                    <div class="flex flex-col">
                      <div class="flex items-center">
                        <div class="h-10 w-10 flex-shrink-0">
                          <img class="h-10 w-10" :src="product.imageUrl" alt="" />
                        </div>
                        <div class="ml-4">
                          <div class="font-medium text-gray-900">
                            {{ product.title }}
                          </div>
                          <div class="text-gray-500">
                            {{ formatPrice(product.variantSmallestPrice) }}
                          </div>
                          <div v-if="product.referenceId !== null">Reference ID : {{ product.referenceId }}</div>
                          <div>Estaly ID : {{ product.id }}</div>
                        </div>
                      </div>
                      <ProductStatusSpanList class="mt-2" v-bind:product="product" />
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <ProductCategoryDisplayComponent v-bind:product="product" />
                  </td>
                  <td class="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                    <ProductActionButtons v-if="product.source !== 'offline'" v-bind:product="product" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductActionButtons from "@/views/products/list/content/actions/ProductActionButtons.vue";
import ProductStatusSpanList from "@/views/products/list/content/status/ProductStatusSpanList.vue";
import { formatPrice } from "@estaly/ui/src/utils/price_formatter";
import ProductCategoryDisplayComponent from "@/views/products/list/content/actions/ProductCategoryDisplayComponent.vue";
export default {
  components: {
    ProductCategoryDisplayComponent,
    ProductActionButtons,
    ProductStatusSpanList,
  },
  props: ["products", "selectedProducts", "allProductsSelected"],

  computed: {
    areAllProductsSelected() {
      const arePagedMatchedProductsAllSelected =
        this.selectedProducts.length !== 0 &&
        this.products.filter((p) => this.isProductApproved(p)).length === this.selectedProducts.length;
      return arePagedMatchedProductsAllSelected || this.allProductsSelected;
    },
    getOrderedProducts() {
      return [...this.products].sort((a, b) => (a.enabled < b.enabled ? 1 : -1));
    },
  },
  methods: {
    formatPrice,
    isProductApproved(product) {
      return product.status === "Matched";
    },
    isProductSelected(product) {
      return this.selectedProducts.includes(product.id) || this.allProductsSelected;
    },
    handleProductSelection(event, product) {
      if (event.target.checked) {
        this.selectProducts([product]);
      } else {
        this.unselectProducts([product]);
      }
    },
    handleProductsSelection(event) {
      if (!this.allProductsSelected) {
        if (event.target.checked) {
          this.selectProducts(this.products);
        } else {
          this.unselectProducts(this.products);
        }
      }
    },
    selectProducts(products) {
      this.$emit("selectProducts", products);
    },
    unselectProducts(products) {
      this.$emit("unselectProducts", products);
    },

    redirectToProductPage(product) {
      this.$router.push(`/products/${product.id}`);
    },
  },
};
</script>
